import axios from "axios";
import CONFIG from "../config";

const { API_URL } = CONFIG;

const instance = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

export default instance;
